@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f4f3ef00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(325deg, #4f57b7 0, #1c2135 0%);
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 38, 135, 0); }

::-webkit-scrollbar {
  width: 0.2px;
  height: 0px; }

label {
  font-size: 12px;
  color: black;
  font-family: "Inter", "sans-serif"; }

.big-title-input > textarea {
  font-size: 24px !important;
  border-bottom: #b3b3b3; }

*:focus,
*:active,
input,
textarea,
select,
option {
  outline: none !important; }

.sticky-thc #tabs {
  position: sticky;
  top: 0px;
  padding-top: 8px;
  padding-bottom: 8px; }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

/*Theme Colors*/
/*Borders*/
.top-nav {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px); }
  .top-nav .nav-text {
    background: linear-gradient(154deg, #6775f5 7.06%, #a193f2 88.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.tapbar {
  background: #fff;
  box-shadow: 0px -4px 6px -4px rgba(0, 0, 0, 0.1), 0px 2px 15px -3px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }

.side-nav {
  border-right: 1px solid var(--neutral-200, #e7e6e5);
  background: linear-gradient(177deg, #fdf2ff 0%, #fff2f9 100%);
  max-width: 224px;
  width: 100%; }

.menuitem {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #363430;
  border-right: 5px solid transparent;
  padding: 16px;
  line-height: 24px;
  font-weight: 300; }
  .menuitem:hover {
    background: rgba(48, 23, 9, 0.1);
    background: #fff;
    border-radius: 16px 0px 0px 16px; }
  .menuitem.active {
    font-weight: 600;
    background: #fff;
    border-radius: 16px 0px 0px 16px; }

.app-bg {
  background: radial-gradient(201.74% 141.42% at 100% 100%, #232968 0%, #282724 100%), radial-gradient(119.88% 111.8% at 100% 100%, #404040 0%, #2e2e2e 100%);
  min-height: 100vh; }

.secondaryNavItem:hover {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.15); }

.nav-selected {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.15); }

.secondary-nav {
  background: white;
  box-shadow: 0px 8px 16px rgba(73, 81, 151, 0.11);
  border-radius: 0px 2px 2px 0px; }

.secondary-nav-laptop.nav-bg {
  border-radius: 32px 16px 16px 32px;
  background: #312f36;
  backdrop-filter: blur(20px); }

.nav-bg-mobile {
  background: #312f36;
  backdrop-filter: blur(20px); }

.primary-sidebar-width {
  width: 5rem; }

.secondary-sidebar-width {
  width: 16rem; }

/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/* Main Theme Colors*/
.font-indigo-10 {
  color: #f6f8ff; }

.font-indigo-50 {
  color: #ebedf7; }

.font-indigo-100 {
  color: #f0f1f8; }

.font-indigo-200 {
  color: #e8ebff; }

.font-indigo-250 {
  color: #a4abcb; }

.font-indigo-300 {
  color: #8da1fc; }

.font-indigo-400 {
  color: #6775f5; }

.font-indigo-550 {
  color: #4f57b7; }

.font-indigo-650 {
  color: #1e2447; }

.font-indigo-700 {
  color: #384486; }

.bg-indigo-10 {
  background-color: #f6f8ff; }

.bg-indigo-50 {
  background-color: #ebedf7; }

.bg-indigo-100 {
  background-color: #f0f1f8; }

.bg-indigo-200 {
  background-color: #e8ebff; }

.bg-indigo-250 {
  background-color: #a4abcb; }

.bg-indigo-300 {
  background-color: #8da1fc; }

.bg-indigo-400 {
  background-color: #6775f5; }

.bg-indigo-550 {
  background-color: #4f57b7; }

.bg-indigo-650 {
  background-color: #1e2447; }

.bg-indigo-700 {
  background-color: #384486; }

.border-indigo-10 {
  border-color: #f6f8ff; }

.border-indigo-50 {
  border-color: #ebedf7; }

.border-indigo-100 {
  border-color: #f0f1f8; }

.border-indigo-200 {
  border-color: #e8ebff; }

.border-indigo-250 {
  border-color: #a4abcb; }

.border-indigo-300 {
  border-color: #8da1fc; }

.border-indigo-400 {
  border-color: #6775f5; }

.border-indigo-550 {
  border-color: #4f57b7; }

.border-indigo-650 {
  border-color: #1e2447; }

.border-indigo-700 {
  border-color: #384486; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-650 {
  color: #373739; }

.font-gray-800 {
  color: #363430; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-650 {
  background-color: #373739; }

.bg-gray-800 {
  background-color: #363430; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-650 {
  border-color: #373739; }

.border-gray-800 {
  border-color: #363430; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

.font-yellow-50 {
  color: #fef6e9; }

.font-yellow-100 {
  color: #fde4be; }

.font-yellow-500 {
  color: #f7cd16; }

.font-yellow-550 {
  color: #f1c21b; }

.font-yellow-600 {
  color: #e0b808; }

.font-yellow-700 {
  color: #6b5f1c; }

.font-yellow-750 {
  color: #5b511b; }

.font-yellow-800 {
  color: #ba7507; }

.font-yellow-850 {
  color: #935d05; }

.font-yellow-900 {
  color: #532a01; }

.font-yellow-1000 {
  color: #301709; }

.bg-yellow-50 {
  background-color: #fef6e9; }

.bg-yellow-100 {
  background-color: #fde4be; }

.bg-yellow-500 {
  background-color: #f7cd16; }

.bg-yellow-550 {
  background-color: #f1c21b; }

.bg-yellow-600 {
  background-color: #e0b808; }

.bg-yellow-700 {
  background-color: #6b5f1c; }

.bg-yellow-750 {
  background-color: #5b511b; }

.bg-yellow-800 {
  background-color: #ba7507; }

.bg-yellow-850 {
  background-color: #935d05; }

.bg-yellow-900 {
  background-color: #532a01; }

.bg-yellow-1000 {
  background-color: #301709; }

.border-yellow-50 {
  border-color: #fef6e9; }

.border-yellow-100 {
  border-color: #fde4be; }

.border-yellow-500 {
  border-color: #f7cd16; }

.border-yellow-550 {
  border-color: #f1c21b; }

.border-yellow-600 {
  border-color: #e0b808; }

.border-yellow-700 {
  border-color: #6b5f1c; }

.border-yellow-750 {
  border-color: #5b511b; }

.border-yellow-800 {
  border-color: #ba7507; }

.border-yellow-850 {
  border-color: #935d05; }

.border-yellow-900 {
  border-color: #532a01; }

.border-yellow-1000 {
  border-color: #301709; }

.font-orange-50 {
  color: #fff1e8; }

.font-orange-100 {
  color: #ffd4bb; }

.font-orange-500 {
  color: #ff9000; }

.font-orange-600 {
  color: #db7c00; }

.font-orange-700 {
  color: #c44900; }

.font-orange-800 {
  color: #983800; }

.bg-orange-50 {
  background-color: #fff1e8; }

.bg-orange-100 {
  background-color: #ffd4bb; }

.bg-orange-500 {
  background-color: #ff9000; }

.bg-orange-600 {
  background-color: #db7c00; }

.bg-orange-700 {
  background-color: #c44900; }

.bg-orange-800 {
  background-color: #983800; }

.border-orange-50 {
  border-color: #fff1e8; }

.border-orange-100 {
  border-color: #ffd4bb; }

.border-orange-500 {
  border-color: #ff9000; }

.border-orange-600 {
  border-color: #db7c00; }

.border-orange-700 {
  border-color: #c44900; }

.border-orange-800 {
  border-color: #983800; }

.font-green-20 {
  color: #ebf8ec; }

.font-green-50 {
  color: #e7fdf4; }

.font-green-100 {
  color: #b6f9de; }

.font-green-450 {
  color: #2bb656; }

.font-green-500 {
  color: #0a8754; }

.font-green-550 {
  color: #6bd425; }

.font-green-600 {
  color: #086842; }

.font-green-700 {
  color: #008b46; }

.font-green-900 {
  color: #012b20; }

.bg-green-20 {
  background-color: #ebf8ec; }

.bg-green-50 {
  background-color: #e7fdf4; }

.bg-green-100 {
  background-color: #b6f9de; }

.bg-green-450 {
  background-color: #2bb656; }

.bg-green-500 {
  background-color: #0a8754; }

.bg-green-550 {
  background-color: #6bd425; }

.bg-green-600 {
  background-color: #086842; }

.bg-green-700 {
  background-color: #008b46; }

.bg-green-900 {
  background-color: #012b20; }

.border-green-20 {
  border-color: #ebf8ec; }

.border-green-50 {
  border-color: #e7fdf4; }

.border-green-100 {
  border-color: #b6f9de; }

.border-green-450 {
  border-color: #2bb656; }

.border-green-500 {
  border-color: #0a8754; }

.border-green-550 {
  border-color: #6bd425; }

.border-green-600 {
  border-color: #086842; }

.border-green-700 {
  border-color: #008b46; }

.border-green-900 {
  border-color: #012b20; }

.font-blue-50 {
  color: #e8f0f8; }

.font-blue-100 {
  color: #b9d2e9; }

.font-blue-550 {
  color: #3778c6; }

.font-blue-700 {
  color: #13293d; }

.font-blue-800 {
  color: #0f2131; }

.bg-blue-50 {
  background-color: #e8f0f8; }

.bg-blue-100 {
  background-color: #b9d2e9; }

.bg-blue-550 {
  background-color: #3778c6; }

.bg-blue-700 {
  background-color: #13293d; }

.bg-blue-800 {
  background-color: #0f2131; }

.border-blue-50 {
  border-color: #e8f0f8; }

.border-blue-100 {
  border-color: #b9d2e9; }

.border-blue-550 {
  border-color: #3778c6; }

.border-blue-700 {
  border-color: #13293d; }

.border-blue-800 {
  border-color: #0f2131; }

.font-purple-100 {
  color: #f1eaf6; }

.font-purple-550 {
  color: #a346ce; }

.font-purple-600 {
  color: #4b355f; }

.font-purple-700 {
  color: #341e48; }

.bg-purple-100 {
  background-color: #f1eaf6; }

.bg-purple-550 {
  background-color: #a346ce; }

.bg-purple-600 {
  background-color: #4b355f; }

.bg-purple-700 {
  background-color: #341e48; }

.border-purple-100 {
  border-color: #f1eaf6; }

.border-purple-550 {
  border-color: #a346ce; }

.border-purple-600 {
  border-color: #4b355f; }

.border-purple-700 {
  border-color: #341e48; }

.font-red-100 {
  color: #fcf0f1; }

.font-red-150 {
  color: #ffe2df; }

.font-red-200 {
  color: #f7d1d4; }

.font-red-500 {
  color: #ed4c41; }

.font-red-550 {
  color: #db2b39; }

.font-red-600 {
  color: #c61e29; }

.font-red-700 {
  color: #af1e2a; }

.bg-red-100 {
  background-color: #fcf0f1; }

.bg-red-150 {
  background-color: #ffe2df; }

.bg-red-200 {
  background-color: #f7d1d4; }

.bg-red-500 {
  background-color: #ed4c41; }

.bg-red-550 {
  background-color: #db2b39; }

.bg-red-600 {
  background-color: #c61e29; }

.bg-red-700 {
  background-color: #af1e2a; }

.border-red-100 {
  border-color: #fcf0f1; }

.border-red-150 {
  border-color: #ffe2df; }

.border-red-200 {
  border-color: #f7d1d4; }

.border-red-500 {
  border-color: #ed4c41; }

.border-red-550 {
  border-color: #db2b39; }

.border-red-600 {
  border-color: #c61e29; }

.border-red-700 {
  border-color: #af1e2a; }

.font-magenta-100 {
  color: #f9e3fc; }

.font-magenta-150 {
  color: #f4c4f7; }

.font-magenta-200 {
  color: #f282f5; }

.font-magenta-500 {
  color: #c44eb9; }

.font-magenta-550 {
  color: #903795; }

.font-magenta-600 {
  color: #451451; }

.font-magenta-700 {
  color: #2a192d; }

.bg-magenta-100 {
  background-color: #f9e3fc; }

.bg-magenta-150 {
  background-color: #f4c4f7; }

.bg-magenta-200 {
  background-color: #f282f5; }

.bg-magenta-500 {
  background-color: #c44eb9; }

.bg-magenta-550 {
  background-color: #903795; }

.bg-magenta-600 {
  background-color: #451451; }

.bg-magenta-700 {
  background-color: #2a192d; }

.border-magenta-100 {
  border-color: #f9e3fc; }

.border-magenta-150 {
  border-color: #f4c4f7; }

.border-magenta-200 {
  border-color: #f282f5; }

.border-magenta-500 {
  border-color: #c44eb9; }

.border-magenta-550 {
  border-color: #903795; }

.border-magenta-600 {
  border-color: #451451; }

.border-magenta-700 {
  border-color: #2a192d; }

/*Theme Colors*/
/*Borders*/
.primary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: linear-gradient(154deg, #6775f5 7.06%, #a193f2 88.02%), #8da1fc;
  border-radius: 8px; }
  .primary-cta:disabled {
    color: #e1e1e1; }
  .primary-cta:disabled {
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5); }

.tertiary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #384486; }
  .tertiary-cta:disabled {
    color: #e1e1e1; }
  .tertiary-cta:hover {
    text-decoration: underline; }
  .tertiary-cta:active {
    text-decoration: underline;
    color: #384486; }

.tertiary-red-cta {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #db2b39; }
  .tertiary-red-cta:disabled {
    color: #e1e1e1; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #a346ce; }

.secondary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #384486;
  border-radius: 8px;
  border: 1px solid #384486;
  color: #384486;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    color: #384486; }
  .secondary-cta:active {
    color: #384486; }
  .secondary-cta:disabled {
    border: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

/*Theme Colors*/
/*Borders*/
.poppins {
  font-family: "Karla", sans-serif; }

.inter {
  font-family: "Lato", sans-serif; }

.theme-input-dark {
  border-radius: 8px;
  border: 1px solid #696763;
  background: rgba(255, 255, 255, 0.1); }
  .theme-input-dark.border-0 {
    border: 0px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 2px 1px 3px 0px rgba(0, 0, 0, 0.1), -1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px -1px 2px 0px rgba(0, 0, 0, 0.04); }
  .theme-input-dark:hover {
    cursor: pointer; }
  .theme-input-dark:active, .theme-input-dark:focus {
    border: 1px solid #747474;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); }

.input-label {
  color: #9c9a96;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 21px */
  letter-spacing: 0.7px; }
